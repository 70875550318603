import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import celebreate_gif from "./images/celebreate.gif";
import GlowingGlobe from "./GlowingGlobe";
import wine_gif from "./images/wine.gif";
import alien_img from "./images/featured_channel.png";

const Footer = () => (
  <footer className="bg-better-black text-white p-4 -mt-12">
    <div className="container mx-auto flex justify-center space-x-4">
      <Link to="/terms-of-service" className="hover:underline">
        Terms of Service
      </Link>
      <Link to="/privacy-policy" className="hover:underline">
        Privacy Policy
      </Link>
    </div>
  </footer>
);

const App = () => {
  const contactRef = useRef(null);
  const portfoliotRef = useRef(null);
  const navigate = useNavigate();

  const handleScrollToSection = (ref) => {
    if (ref.current) {
      const elementPosition =
        ref.current.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - 60;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="text-white font-sans bg-better-black">
      <header className="bg-better-black text-white py-4 fixed w-full z-10">
        <div className="flex justify-between items-center px-2 md:px-4 lg:px-8">
          <div className="flex  justify-start">
            <img src={alien_img} alt="Logo" className="h-10 mr-4" />
          </div>
          <nav className="flex space-x-8">
            <button
              onClick={() => navigate("/about-the-company")}
              className="hidden md:block hover:text-gray-400"
            >
              About The Company
            </button>
            <button
              onClick={() => handleScrollToSection(portfoliotRef)}
              className="hidden md:block hover:text-gray-400"
            >
              Portfolio
            </button>
            <button
              className="bg-white text-black px-4 py-2 rounded-xl text-xl font-semibold"
              onClick={() => handleScrollToSection(contactRef)}
            >
              Get In Touch 🙂
            </button>
          </nav>
        </div>
      </header>

      <div className="text-center rounded-lg max-w-9xl">
        <div className="py-24 flex flex-col md:flex-row items-center md:space-x-8 pb-8 px-8 md:py-12 lg:px-12 min-h-screen">
          <div className="md:w-2/3 lg:w-1/2 text-center md:text-left">
            <h1
              className="text-5xl md:text-6xl font-bold mb-4 pt-8 md:pt-0"
              style={{ lineHeight: "80px" }}
            >
              {" "}
              Alien Technologies LLC{" "}
            </h1>
            <p className="text-xl mb-8">
              We are a software development company specializing in creating
              innovative software solutions for various fields.
            </p>
          </div>
          <div className="md:w-1/3 lg:w-1/2 md:mt-0 flex flex-col items-center text-center">
            <GlowingGlobe />
          </div>
        </div>

        <div ref={portfoliotRef} className="flex flex-col items-center">
          <h2 className="text-6xl font-bold text-center text-black mb-8 bg-white px-4 py-2 inline rounded-xl">
            Our Projects and Products
          </h2>

          <div className="bg-dark-black rounded-2xl p-12 mt-24 text-center max-w-6xl">
            <h3 className="text-5xl font-bold mb-2 flex items-center justify-center pb-8">
              Better Evaluation Tools For Cancer Guidelines
            </h3>
            <p className="text-gray-400 mb-6 text-2xl">
              We've built tools to help evaluate cancer research papers faster.
            </p>
            <div className="bg-white text-black rounded-lg p-6 shadow-lg">
              <img
                src={require("./images/abstimmung.png")}
                alt="App Screenshot"
                className="mx-auto w-full h-auto rounded-xl"
              />
            </div>
          </div>

          <div className="text-center p-8 rounded-lg max-w-6xl flex flex-col items-center">
            <div className="py-24 flex flex-col md:flex-row items-center justify-center md:space-x-8 pb-8 px-8 md:py-12 lg:px-12 min-h-screen md:min-h-[69vh]">
              <div className="md:w-2/3 lg:w-1/2 text-center">
                <h1
                  className="text-5xl md:text-6xl font-bold mb-4"
                  style={{ lineHeight: "80px" }}
                >
                  Our Partner: The German Cancer Society
                </h1>
                <p className="text-xl mb-8">
                  We are a software development company specializing in creating
                  innovative software solutions for various fields.
                </p>
              </div>
              <div className="md:w-1/3 lg:w-1/2 mt-8 md:mt-0 flex items-center justify-center">
                <div className="w-full flex justify-center px-4 lg:px-8 mt-4">
                  <img
                    src={require("./images/logo_ohneText.png")}
                    alt="App Screenshot"
                    className="max-w-full h-auto"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="bg-dark-black rounded-2xl p-12 mt-24 text-center max-w-6xl">
            <h3 className="text-5xl font-bold mb-2 flex items-center justify-center pb-8">
              Breathing Frog, a better breath holds training app
            </h3>
            <p className="text-gray-400 mb-6 mt-4 text-2xl">
              We've built tools to help evaluate cancer research papers faster.
            </p>
            <div className="bg-white text-black rounded-lg p-6 shadow-lg">
              <img
                src={require("./images/breathingapp.png")}
                alt="App Screenshot"
                className="mx-auto w-full h-auto rounded-xl"
              />
            </div>
          </div>
        </div>

        <h1 className="text-5xl font-bold mb-4 mt-32">
          Want to Work With Us?! 🎉
        </h1>
        <p className="text-2xl text-better-gray mb-4">
          Please get in touch. We're happy to have you as a client!
        </p>

        <img src={celebreate_gif} className="pb-24 mx-auto" />

        <section
          ref={contactRef}
          className="flex flex-col items-center justify-center text-center space-y-8 mt-12"
        >
          <div className="px-12 py-12 rounded-3xl">
            <h2 className="text-6xl font-bold text-center text-black mb-8 bg-white px-4 py-2 inline rounded-xl">
              How to Reach Out
            </h2>
            <p className="text-3xl text-white mb-2 pt-12">
              Drop us an email at: hello [at] alientechnologies [dot] com
            </p>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default App;
