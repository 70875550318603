import React from "react";
import { useNavigate } from "react-router-dom";

import alien_img from "./images/featured_channel.png";

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-better-black text-white min-h-screen">
      <header className="bg-better-black text-white py-4 fixed w-full z-10">
        <div className="container mx-auto flex justify-between items-center px-4">
          <div className="flex items-center mx-auto md:mx-0">
            <img src={alien_img} alt="Logo" className="h-10 mr-4" />
          </div>
          <nav className="hidden md:flex space-x-8">
            <button
              className="bg-white text-black px-4 py-2 rounded-xl text-xl font-semibold"
              onClick={() => navigate("/")}
            >
              Back To Homepage
            </button>
          </nav>
        </div>
      </header>

      <div className="container mx-auto pt-40">
        <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
        <p className="mb-6">Last updated: Sep 01, 2024</p>

        <p className="mb-6">
          Alien Technologies LLC takes your privacy seriously. To better protect
          your privacy, Alien Technologies LLC provides this privacy policy
          notice explaining the way your personal information is collected and
          used.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          Collection of Routine Information
        </h2>
        <p className="mb-4">
          This website tracks basic information about its visitors. This
          information includes, but is not limited to, IP addresses, browser
          details, timestamps, and referring pages. None of this information can
          personally identify specific visitors to this website. The information
          is tracked for routine administration and maintenance purposes.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">Cookies</h2>
        <p className="mb-4">
          Where necessary, this website uses cookies to store information about
          a visitor’s preferences and history in order to better serve the
          visitor and/or present the visitor with customized content.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          Links to Third Party Websites
        </h2>
        <p className="mb-4">
          Alien Technologies LLC has included links on this website for your use
          and reference. Alien Technologies LLC is not responsible for the
          privacy policies on these websites. You should be aware that the
          privacy policies of these websites may differ from our own.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">Security</h2>
        <p className="mb-4">
          The security of your personal information is important to Alien
          Technologies LLC, but remember that no method of transmission over the
          Internet, or method of electronic storage, is 100% secure. While Alien
          Technologies LLC strives to use commercially acceptable means to
          protect your personal information, we cannot guarantee its absolute
          security.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          Changes To This Privacy Policy
        </h2>
        <p className="mb-4">
          This Privacy Policy is effective as of [Date] and will remain in
          effect except with respect to any changes in its provisions in the
          future, which will be in effect immediately after being posted on this
          page.
        </p>
        <p className="mb-4">
          Alien Technologies LLC reserves the right to update or change this
          Privacy Policy at any time and you should check this Privacy Policy
          periodically. If Alien Technologies LLC makes any material changes to
          this Privacy Policy, we will notify you either through the email
          address you have provided us or by placing a prominent notice on our
          website.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          Contact Information
        </h2>
        <p className="pb-12">
          For any questions or concerns regarding the privacy policy, please
          send us an email at [Contact Email Address].
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
