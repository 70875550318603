import React from "react";
import { Link, useNavigate } from "react-router-dom";
import alien_img from "./images/featured_channel.png";
import mypgoto from "./images/founder_photo.png";

const Footer = () => (
  <footer className="bg-better-black text-white p-4 mt-24 pb-0">
    <div className="container mx-auto flex justify-center space-x-4">
      <Link to="/terms-of-service" className="hover:underline">
        Terms of Service
      </Link>
      <Link to="/privacy-policy" className="hover:underline">
        Privacy Policy
      </Link>
    </div>
  </footer>
);

const AboutUs = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-better-black text-white min-h-screen pb-4">
      <header className="bg-better-black text-white py-4 fixed w-full z-10">
        <div className="container mx-auto flex justify-between items-center px-4">
          <div className="flex items-center mx-auto md:mx-0">
            <img src={alien_img} alt="Logo" className="h-10 mr-4" />
          </div>
          <nav className="hidden md:flex space-x-8">
            <button
              className="bg-white text-black px-4 py-2 rounded-xl text-xl font-semibold"
              onClick={() => navigate("/")}
            >
              Back To Homepage
            </button>
          </nav>
        </div>
      </header>

      <div className="container mx-auto pt-40">
        <h1 className="text-7xl font-bold mb-6 text-center">About Us</h1>

        <h2 className="text-4xl font-semibold mt-8 mb-4 pt-24">
          About the Company
        </h2>
        <p className="mb-4">
          Alien Technologies LLC is a software development company specializing
          in creating innovative software solutions across various fields. We
          are dedicated to delivering high-quality, scalable, and user-friendly
          applications that meet the unique needs of our clients.
        </p>
        <p className="mb-4">
          Based in Sheridan, Wyoming, our legal address is located at:
        </p>
        <address className="mb-4">
          30 N Gould St Ste R,
          <br />
          Sheridan, WY 82801, USA
        </address>

        <div className="flex flex-col md:flex-row items-center justify-between mt-24">
          <div className="md:w-1/2 lg:w-2/3 text-left px-12">
            <h2 className="text-4xl font-semibold mb-4">About the Founder</h2>
            <p className="mb-4">
              Ahmed Khelifi is the passionate software developer behind Alien
              Technologies LLC. With a deep love for coding and an unyielding
              drive to innovate, Ahmed has built a career on creating impactful
              software that solves real-world problems. His enthusiasm for
              technology is matched only by his love of travel, which often
              inspires his work and brings a global perspective to his projects.
            </p>
          </div>
          <div className="md:w-1/2 lg:w-1/3 mt-8 md:mt-0 flex justify-center">
            <img
              src={mypgoto}
              alt="Founder"
              className="rounded-xl max-w-full h-auto"
            />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default AboutUs;
