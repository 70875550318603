import React from "react";
import { useNavigate } from "react-router-dom";

import alien_img from "./images/featured_channel.png";

const TermsOfService = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-better-black text-white min-h-screen">
      <header className="bg-better-black text-white py-4 fixed w-full z-10">
        <div className="container mx-auto flex justify-between items-center px-4">
          <div className="flex items-center mx-auto md:mx-0">
            <img src={alien_img} alt="Logo" className="h-10 mr-4" />
          </div>
          <nav className="hidden md:flex space-x-8">
            <button
              className="bg-white text-black px-4 py-2 rounded-xl text-xl font-semibold"
              onClick={() => navigate("/")}
            >
              Back To Homepage
            </button>
          </nav>
        </div>
      </header>

      <div className="container mx-auto pt-40">
        <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>
        <p className="mb-6">Last updated: Sep 01, 2024</p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          1. Agreement to Terms
        </h2>
        <p className="mb-4">
          These Terms of Service ("Terms") constitute a legally binding
          agreement between you ("User", "you", or "your") and Alien
          Technologies LLC ("Company", "we", "us", or "our"). By accessing or
          using our website located at [Your Website URL], you agree to comply
          with and be bound by these Terms.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">2. Eligibility</h2>
        <p className="mb-4">
          You must be at least 18 years of age or the age of majority in your
          jurisdiction to use our services. By using our website, you represent
          and warrant that you have the legal capacity to enter into this
          agreement.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          3. Changes to Terms
        </h2>
        <p className="mb-4">
          We reserve the right to modify these Terms at any time. Any changes
          will be effective immediately upon posting on this page. It is your
          responsibility to review these Terms periodically. Your continued use
          of the website after changes are posted will constitute your
          acceptance of the modified Terms.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">4. Use of Services</h2>
        <p className="mb-4">
          You agree to use our services only for lawful purposes and in
          accordance with these Terms. You must not use our services:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>
            In any way that violates any applicable federal, state, local, or
            international law or regulation.
          </li>
          <li>
            For the purpose of exploiting, harming, or attempting to exploit or
            harm minors in any way.
          </li>
          <li>
            To send, knowingly receive, upload, download, use, or re-use any
            material that does not comply with our content standards.
          </li>
          <li>
            To transmit, or procure the sending of, any advertising or
            promotional material, including any "junk mail," "chain letter,"
            "spam," or any other similar solicitation.
          </li>
          <li>
            To impersonate or attempt to impersonate Alien Technologies LLC, a
            company employee, another user, or any other person or entity.
          </li>
          <li>
            To engage in any other conduct that restricts or inhibits anyone's
            use or enjoyment of the services, or which, as determined by us, may
            harm Alien Technologies LLC or users of the services or expose them
            to liability.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">5. User Accounts</h2>
        <p className="mb-4">
          If you create an account with us, you are responsible for maintaining
          the confidentiality of your account and password, and for restricting
          access to your computer or device. You agree to accept responsibility
          for all activities that occur under your account. You must notify us
          immediately of any unauthorized use of your account or any other
          breach of security.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          6. Intellectual Property Rights
        </h2>
        <p className="mb-4">
          All content, trademarks, and other intellectual property on the
          website, including but not limited to text, graphics, logos, and
          software, are the property of Alien Technologies LLC or its licensors.
          You may not reproduce, distribute, modify, or otherwise exploit any
          part of the website without our express written consent.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          7. Third-Party Links
        </h2>
        <p className="mb-4">
          Our website may contain links to third-party websites or services that
          are not owned or controlled by Alien Technologies LLC. We have no
          control over, and assume no responsibility for, the content, privacy
          policies, or practices of any third-party websites or services. You
          acknowledge and agree that Alien Technologies LLC shall not be
          responsible or liable for any damage or loss caused by or in
          connection with the use of any such content, goods, or services
          available on or through any such websites or services.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          8. Disclaimer of Warranties
        </h2>
        <p className="mb-4">
          The website is provided on an "as is" and "as available" basis without
          warranties of any kind, either express or implied, including but not
          limited to the implied warranties of merchantability, fitness for a
          particular purpose, or non-infringement. We do not warrant that the
          website will be uninterrupted or error-free, nor do we make any
          warranty as to the accuracy, reliability, or currency of any
          information provided through the website.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          9. Limitation of Liability
        </h2>
        <p className="mb-4">
          To the fullest extent permitted by applicable law, in no event shall
          Alien Technologies LLC, its affiliates, directors, employees, or
          agents be liable for any indirect, incidental, special, consequential,
          or punitive damages, including without limitation, loss of profits,
          data, use, goodwill, or other intangible losses, resulting from your
          access to or use of or inability to access or use the website or any
          services provided through the website.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          10. Indemnification
        </h2>
        <p className="mb-4">
          You agree to defend, indemnify, and hold harmless Alien Technologies
          LLC, its affiliates, and their respective directors, officers,
          employees, and agents from and against any claims, liabilities,
          damages, judgments, awards, losses, costs, expenses, or fees
          (including reasonable attorneys' fees) arising out of or relating to
          your violation of these Terms or your use of the website, including,
          but not limited to, any use of the website's content, services, and
          products other than as expressly authorized in these Terms or your use
          of any information obtained from the website.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          11. Governing Law and Jurisdiction
        </h2>
        <p className="mb-4">
          These Terms and your use of the website shall be governed by and
          construed in accordance with the laws of the State of Wyoming, USA,
          without regard to its conflict of law provisions. You agree to submit
          to the personal jurisdiction of the state and federal courts located
          in Sheridan, Wyoming, USA, for any actions for which we retain the
          right to seek injunctive or other equitable relief in a court of
          competent jurisdiction to prevent the actual or threatened
          infringement, misappropriation, or violation of our copyrights,
          trademarks, trade secrets, patents, or other intellectual property
          rights.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">12. Severability</h2>
        <p className="mb-4">
          If any provision of these Terms is held to be invalid or unenforceable
          by a court of competent jurisdiction, the remaining provisions will
          continue in full force and effect.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          13. Entire Agreement
        </h2>
        <p className="mb-4">
          These Terms constitute the entire agreement between you and Alien
          Technologies LLC regarding your use of the website, and supersede all
          prior agreements, understandings, representations, and warranties,
          both written and oral, regarding such subject matter.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">
          14. Contact Information
        </h2>
        <p className="mb-4">
          If you have any questions about these Terms, please contact us at:
        </p>
        <address className="pb-12">
          Alien Technologies LLC
          <br />
          30 N Gould ST STE R<br />
          Sheridan, WY 82801, USA
          <br />
          Email: hello [at] alientechnologies [dot] com
        </address>
      </div>
    </div>
  );
};

export default TermsOfService;
