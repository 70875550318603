"use client";

import React, { useRef } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { Points, PointMaterial } from "@react-three/drei";
import * as THREE from "three";

function Globe() {
  const points = useRef();

  useFrame((state, delta) => {
    if (points.current) {
      points.current.rotation.y += delta * 0.1;
    }
  });

  const particlesPosition = React.useMemo(() => {
    const positions = new Float32Array(10000 * 3);
    const radius = 2;

    for (let i = 0; i < 10000; i++) {
      const theta = THREE.MathUtils.randFloatSpread(360);
      const phi = THREE.MathUtils.randFloatSpread(360);

      const x = radius * Math.sin(theta) * Math.cos(phi);
      const y = radius * Math.sin(theta) * Math.sin(phi);
      const z = radius * Math.cos(theta);

      positions.set([x, y, z], i * 3);
    }

    return positions;
  }, []);

  return (
    <Points
      ref={points}
      positions={particlesPosition}
      stride={3}
      frustumCulled={false}
    >
      <PointMaterial
        transparent
        color="#ffffff"
        size={0.02}
        sizeAttenuation={true}
        depthWrite={false}
        blending={THREE.AdditiveBlending}
      />
    </Points>
  );
}

export default function Component() {
  return (
    <div className="w-full h-[50vh] md:h-screen">
      <Canvas camera={{ position: [0, 0, 6] }}>
        <ambientLight intensity={0.5} />
        <Globe />
      </Canvas>
    </div>
  );
}
